@import 'normalize';

@font-face {
    font-family: 'aperculight';
    src: url('../fonts/apercu-light-webfont.eot');
    src: url('../fonts/apercu-light-webfont.eot?#iefix')
            format('embedded-opentype'),
        url('../fonts/apercu-light-webfont.woff2') format('woff2'),
        url('../fonts/apercu-light-webfont.woff') format('woff'),
        url('../fonts/apercu-light-webfont.ttf') format('truetype'),
        url('../fonts/apercu-light-webfont.svg#aperculight') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'apercumedium';
    src: url('../fonts/apercu_medium-webfont.eot');
    src: url('../fonts/apercu_medium-webfont.eot?#iefix')
            format('embedded-opentype'),
        url('../fonts/apercu_medium-webfont.woff2') format('woff2'),
        url('../fonts/apercu_medium-webfont.woff') format('woff'),
        url('../fonts/apercu_medium-webfont.ttf') format('truetype'),
        url('../fonts/apercu_medium-webfont.svg#aperculight') format('svg');
    font-weight: normal;
    font-style: normal;
}

html,
body {
    height: 100%;
    width: 100%;
    margin: 0;
    min-height: 350px;
    font-family: 'aperculight', serif;
}

#logo {
    font-size: 24px;
    position: absolute;
    top: 18px;
    left: 18px;
    z-index: 3;
    font-family: 'apercumedium', serif;
    display: inline-block;

    cursor: pointer;

    a {
        color: #000000;
        display: inline-block;
        line-height: 0.9;
        text-decoration: none;
        border-bottom: 2px solid black;

        &:hover {
            //text-decoration: underline;
        }
    }
}

.fb-share {
    position: absolute;
    top: 18px;
    right: 62px;
    z-index: 2;

    &.mobile {
        top: auto;
        right: auto;
        margin: 0 auto;
        position: relative;
        width: 70px;
    }
}

#mute {
    position: absolute;
    top: 18px;
    right: 18px;
    height: 18px;
    width: 26px;
    background: url('../img/glyphicons-184-volume-down.png') no-repeat center
        center;
    z-index: 2;
    text-indent: 999px;
    overflow: hidden;
    cursor: pointer;

    &.is-muted {
        background-image: url('../img/glyphicons-183-mute.png');
    }
}

#tramclock {
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: relative;
    border: 65px solid white;
    border-left-width: 8px;
    border-right-width: 8px;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

#about,
#loadscreen,
#mobilewarning,
#browserwarning {
    height: 100%;
    width: 100%;
    z-index: 2;
    position: absolute;
    background-color: #f3f3f3;
    font-size: 34px;

    display: none;
    overflow: auto;

    box-sizing: border-box;
    border: 20px solid transparent;

    .wrapper {
        max-width: 560px;
        margin: 0 auto;
        text-align: center;
    }

    h1 {
        font-size: 24px;
        border-bottom: 2px solid black;
        display: inline-block;
    }

    h2 {
        font-size: 1em;
    }

    p {
        font-family: 'apercumedium', serif;
        font-size: 16px;
        width: 100%;
        text-align: left;
    }

    a,
    a:hover,
    a:active {
        color: #000000;

        &.small {
            font-size: 0.5em;
        }
    }

    .loader {
        width: 340px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        align-items: center;

        .logo {
            white-space: nowrap;
            margin-bottom: 10px;
        }

        .startButton {
            box-sizing: border-box;
            appearance: none;
            background: none;
            border: 1px solid black;
            display: block;
            margin-top: 0;
            padding: 3px 10px;
            max-height: 48px;
        }

        #loadimage {
            width: 32px;
            display: none;
            padding: 8px;
        }
    }
}

span.reverse {
    unicode-bidi: bidi-override;
    direction: rtl;
}

#about {
    z-index: 100;
}

#mobilewarning,
#browserwarning {
    z-index: 99;
}

#browserwarning {
    display: block;
}

//::-webkit-scrollbar {
//    -webkit-appearance: none;
//    width: 7px;
//}
//
//::-webkit-scrollbar-thumb {
//    border-radius: 4px;
//    background-color: rgba(0, 0, 0, 0.5);
//    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
//}

#loadscreen {
    display: flex;
    justify-content: center;
    cursor: pointer;
}

footer {
    position: fixed;
    bottom: 0;
    left: 0;
    height: 65px;
    width: 100%;
    background-color: #fff;
    z-index: 2;
    overflow: hidden;
}

#mark {
    width: 7px;
    height: 27px;
    position: absolute;
    top: 19px;
    left: 50%;
    margin-left: -3px;
    background-image: url('../img/mark.png');
}

#timeline {
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -5px;
    width: 400px;
    margin-left: 50%;
    background: url('../img/dotline.png') repeat-x center left;
    height: 10px;
}

.tramdot {
    position: absolute;
    height: 10px;
    width: 10px;
    margin-left: -5px;
    background-image: url('../img/dot.png');
    top: 0;
}

/*  BigVideo Styles
    You may need to adjust these styles to get this working right in your design.
*/

#big-video-wrap {
    overflow: hidden;
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
}

#big-video-vid,
#big-video-image {
    position: absolute;
}

#big-video-control-container {
    position: fixed;
    bottom: 20px;
    padding: 0 20px;
    width: 100%;
    -webkit-transition-duration: 0.6s;
    -moz-transition-duration: 0.6s;
    -ms-transition-duration: 0.6s;
    -o-transition-duration: 0.6s;
    transition-duration: 0.6s;
}

#big-video-control {
    width: 100%;
    height: 16px;
    position: relative;
}

#big-video-control-middle {
    margin: 0 56px 0 24px;
}

#big-video-control-bar {
    width: 100%;
    height: 16px;
    position: relative;
    cursor: pointer;
}

#big-video-control-progress {
    position: absolute;
    top: 7px;
    height: 3px;
    width: 0%;
    background: #fff;
}

#big-video-control-track {
    position: absolute;
    top: 8px;
    height: 1px;
    width: 100%;
    background: #fff;
}

#big-video-control-bound-left {
    left: 0;
}

#big-video-control-bound-right {
    right: -1px;
}

#big-video-control-track .ui-slider-handle {
    opacity: 0;
}

#big-video-control-playhead {
    left: 30%;
}

#big-video-control-play {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
}

#big-video-control-timer {
    position: absolute;
    top: 1px;
    right: 0;
    color: #fff;
    font-size: 11px;
}

#big-video-control-playhead,
#big-video-control-bound-left,
#big-video-control-bound-right {
    position: absolute;
    top: 0;
    height: 16px;
    width: 1px;
    background: #fff;
}

#big-video-control .ui-slider-handle {
    border-left: solid 1px #fff;
    width: 16px;
    height: 16px;
    position: absolute;
    top: -8px;
}

.transparent {
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
    filter: alpha(opacity=0);
    -moz-opacity: 0;
    -khtml-opacity: 0;
    opacity: 0;
}

.vjs-big-play-button {
    display: none !important;
}

#big-video-wrap,
.big-video-wrap {
    overflow: hidden;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;

    &.has-loaded {
        visibility: visible;
    }

    &.is-hidden {
        visibility: hidden;
    }
}

.videocontent {
}

#big-video-vid,
#big-video-image,
.big-video-vid {
    position: absolute;
}

.big-video-vid {
    opacity: 0;
}

.vjs-big-play-button {
    display: none !important;
}

.is-hidden {
    visibility: hidden;
}

.vjs-loading-spinner,
.vjs-waiting .vjs-loading-spinner,
.vjs-seeking .vjs-loading-spinner {
    display: none;
}

/* ==========================================================================
   Media Queries
   ========================================================================== */

@media only screen and (max-height: 350px) {
    footer {
        position: absolute;
        top: 285px;
    }
}

@media only screen and (min-width: 35em) {
}
