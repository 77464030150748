article, aside, details, figcaption, figure, footer, header, hgroup, nav, section, summary {
  display: block;
}

audio, canvas, video {
  display: inline-block;
}

audio:not([controls]) {
  height: 0;
  display: none;
}

[hidden], template {
  display: none;
}

html {
  color: #000;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  background: #fff;
}

html, button, input, select, textarea {
  font-family: sans-serif;
}

body {
  margin: 0;
}

a {
  background: none;
}

a:focus {
  outline: thin dotted;
}

a:hover, a:active {
  outline: 0;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

h2 {
  margin: .83em 0;
  font-size: 1.5em;
}

h3 {
  margin: 1em 0;
  font-size: 1.17em;
}

h4 {
  margin: 1.33em 0;
  font-size: 1em;
}

h5 {
  margin: 1.67em 0;
  font-size: .83em;
}

h6 {
  margin: 2.33em 0;
  font-size: .75em;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b, strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

mark {
  color: #000;
  background: #ff0;
}

code, kbd, pre, samp {
  font-family: monospace, serif;
  font-size: 1em;
}

pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}

q {
  quotes: "“" "”" "‘" "’";
}

q:before, q:after {
  content: "";
  content: none;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sup {
  top: -.5em;
}

sub {
  bottom: -.25em;
}

img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 0;
}

fieldset {
  border: 1px solid silver;
  margin: 0 2px;
  padding: .35em .625em .75em;
}

legend {
  white-space: normal;
  border: 0;
  padding: 0;
}

button, input, select, textarea {
  vertical-align: baseline;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
}

button, input {
  line-height: normal;
}

button, select {
  text-transform: none;
}

button, html input[type="button"], input[type="reset"], input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled], input[disabled] {
  cursor: default;
}

button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

textarea {
  vertical-align: top;
  overflow: auto;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

@font-face {
  font-family: aperculight;
  src: url("apercu-light-webfont.fb769cbb.eot");
  src: url("apercu-light-webfont.fb769cbb.eot#iefix") format("embedded-opentype"), url("apercu-light-webfont.777dc156.woff2") format("woff2"), url("apercu-light-webfont.f441dd2d.woff") format("woff"), url("apercu-light-webfont.ad39b153.ttf") format("truetype"), url("apercu-light-webfont.b0978d9e.svg#aperculight") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: apercumedium;
  src: url("apercu_medium-webfont.9ae1b3cd.eot");
  src: url("apercu_medium-webfont.9ae1b3cd.eot#iefix") format("embedded-opentype"), url("apercu_medium-webfont.b25b8906.woff2") format("woff2"), url("apercu_medium-webfont.8a9ae4c7.woff") format("woff"), url("apercu_medium-webfont.fd563381.ttf") format("truetype"), url("apercu_medium-webfont.878777d4.svg#aperculight") format("svg");
  font-weight: normal;
  font-style: normal;
}

html, body {
  width: 100%;
  height: 100%;
  min-height: 350px;
  margin: 0;
  font-family: aperculight, serif;
}

#logo {
  z-index: 3;
  cursor: pointer;
  font-family: apercumedium, serif;
  font-size: 24px;
  display: inline-block;
  position: absolute;
  top: 18px;
  left: 18px;
}

#logo a {
  color: #000;
  border-bottom: 2px solid #000;
  line-height: .9;
  text-decoration: none;
  display: inline-block;
}

.fb-share {
  z-index: 2;
  position: absolute;
  top: 18px;
  right: 62px;
}

.fb-share.mobile {
  width: 70px;
  margin: 0 auto;
  position: relative;
  top: auto;
  right: auto;
}

#mute {
  z-index: 2;
  text-indent: 999px;
  cursor: pointer;
  background: url("glyphicons-184-volume-down.c5f82191.png") center no-repeat;
  width: 26px;
  height: 18px;
  position: absolute;
  top: 18px;
  right: 18px;
  overflow: hidden;
}

#mute.is-muted {
  background-image: url("glyphicons-183-mute.6f041a7b.png");
}

#tramclock {
  box-sizing: border-box;
  border: 65px solid #fff;
  border-width: 65px 8px;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

#about, #loadscreen, #mobilewarning, #browserwarning {
  z-index: 2;
  box-sizing: border-box;
  background-color: #f3f3f3;
  border: 20px solid #0000;
  width: 100%;
  height: 100%;
  font-size: 34px;
  display: none;
  position: absolute;
  overflow: auto;
}

#about .wrapper, #loadscreen .wrapper, #mobilewarning .wrapper, #browserwarning .wrapper {
  text-align: center;
  max-width: 560px;
  margin: 0 auto;
}

#about h1, #loadscreen h1, #mobilewarning h1, #browserwarning h1 {
  border-bottom: 2px solid #000;
  font-size: 24px;
  display: inline-block;
}

#about h2, #loadscreen h2, #mobilewarning h2, #browserwarning h2 {
  font-size: 1em;
}

#about p, #loadscreen p, #mobilewarning p, #browserwarning p {
  text-align: left;
  width: 100%;
  font-family: apercumedium, serif;
  font-size: 16px;
}

#about a, #about a:hover, #about a:active, #loadscreen a, #loadscreen a:hover, #loadscreen a:active, #mobilewarning a, #mobilewarning a:hover, #mobilewarning a:active, #browserwarning a, #browserwarning a:hover, #browserwarning a:active {
  color: #000;
}

#about a.small, #about a:hover.small, #about a:active.small, #loadscreen a.small, #loadscreen a:hover.small, #loadscreen a:active.small, #mobilewarning a.small, #mobilewarning a:hover.small, #mobilewarning a:active.small, #browserwarning a.small, #browserwarning a:hover.small, #browserwarning a:active.small {
  font-size: .5em;
}

#about .loader, #loadscreen .loader, #mobilewarning .loader, #browserwarning .loader {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 340px;
  height: 100%;
  display: flex;
}

#about .loader .logo, #loadscreen .loader .logo, #mobilewarning .loader .logo, #browserwarning .loader .logo {
  white-space: nowrap;
  margin-bottom: 10px;
}

#about .loader .startButton, #loadscreen .loader .startButton, #mobilewarning .loader .startButton, #browserwarning .loader .startButton {
  box-sizing: border-box;
  appearance: none;
  background: none;
  border: 1px solid #000;
  max-height: 48px;
  margin-top: 0;
  padding: 3px 10px;
  display: block;
}

#about .loader #loadimage, #loadscreen .loader #loadimage, #mobilewarning .loader #loadimage, #browserwarning .loader #loadimage {
  width: 32px;
  padding: 8px;
  display: none;
}

span.reverse {
  unicode-bidi: bidi-override;
  direction: rtl;
}

#about {
  z-index: 100;
}

#mobilewarning, #browserwarning {
  z-index: 99;
}

#browserwarning {
  display: block;
}

#loadscreen {
  cursor: pointer;
  justify-content: center;
  display: flex;
}

footer {
  z-index: 2;
  background-color: #fff;
  width: 100%;
  height: 65px;
  position: fixed;
  bottom: 0;
  left: 0;
  overflow: hidden;
}

#mark {
  background-image: url("mark.b771d62a.png");
  width: 7px;
  height: 27px;
  margin-left: -3px;
  position: absolute;
  top: 19px;
  left: 50%;
}

#timeline {
  background: url("dotline.78f8596c.png") 0 repeat-x;
  width: 400px;
  height: 10px;
  margin-top: -5px;
  margin-left: 50%;
  position: absolute;
  top: 50%;
  left: 0;
}

.tramdot {
  background-image: url("dot.8e2addeb.png");
  width: 10px;
  height: 10px;
  margin-left: -5px;
  position: absolute;
  top: 0;
}

#big-video-wrap {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

#big-video-vid, #big-video-image {
  position: absolute;
}

#big-video-control-container {
  -o-transition-duration: .6s;
  width: 100%;
  padding: 0 20px;
  transition-duration: .6s;
  position: fixed;
  bottom: 20px;
}

#big-video-control {
  width: 100%;
  height: 16px;
  position: relative;
}

#big-video-control-middle {
  margin: 0 56px 0 24px;
}

#big-video-control-bar {
  cursor: pointer;
  width: 100%;
  height: 16px;
  position: relative;
}

#big-video-control-progress {
  background: #fff;
  width: 0%;
  height: 3px;
  position: absolute;
  top: 7px;
}

#big-video-control-track {
  background: #fff;
  width: 100%;
  height: 1px;
  position: absolute;
  top: 8px;
}

#big-video-control-bound-left {
  left: 0;
}

#big-video-control-bound-right {
  right: -1px;
}

#big-video-control-track .ui-slider-handle {
  opacity: 0;
}

#big-video-control-playhead {
  left: 30%;
}

#big-video-control-play {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 0;
  left: 0;
}

#big-video-control-timer {
  color: #fff;
  font-size: 11px;
  position: absolute;
  top: 1px;
  right: 0;
}

#big-video-control-playhead, #big-video-control-bound-left, #big-video-control-bound-right {
  background: #fff;
  width: 1px;
  height: 16px;
  position: absolute;
  top: 0;
}

#big-video-control .ui-slider-handle {
  border-left: 1px solid #fff;
  width: 16px;
  height: 16px;
  position: absolute;
  top: -8px;
}

.transparent {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity= 0);
  -moz-opacity: 0;
  -khtml-opacity: 0;
  opacity: 0;
}

#big-video-wrap, .big-video-wrap {
  opacity: 0;
  visibility: hidden;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

#big-video-wrap.has-loaded, .big-video-wrap.has-loaded {
  visibility: visible;
}

#big-video-wrap.is-hidden, .big-video-wrap.is-hidden {
  visibility: hidden;
}

#big-video-vid, #big-video-image, .big-video-vid {
  position: absolute;
}

.big-video-vid {
  opacity: 0;
}

.vjs-big-play-button {
  display: none !important;
}

.is-hidden {
  visibility: hidden;
}

.vjs-loading-spinner, .vjs-waiting .vjs-loading-spinner, .vjs-seeking .vjs-loading-spinner {
  display: none;
}

@media only screen and (height <= 350px) {
  footer {
    position: absolute;
    top: 285px;
  }
}

/*# sourceMappingURL=index.aa064df2.css.map */
